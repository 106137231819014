import React from "react";
import Button from "@mui/material/Button";
import EI from "./Logo.png";
import "./PoweredBy.css";

const PoweredBy = () => {
  return (
    <Button
      variant="contained"
      size="small" // Using Material-UI's size prop to make the button smaller
      sx={{
        padding: "8px 16px", // Adjust padding as needed
        width: "auto",
        color: "#999999",
        backgroundColor: "#252525",
      }}
    >
      <div className="power">
        Powered by
        <a href="https://excaliburinteractive.org/">
          <img src={EI} alt="User Logo" width={90} height={50} />
        </a>
      </div>
    </Button>
  );
};

export default PoweredBy;
