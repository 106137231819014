import React, { useState, useEffect } from "react";
import "../Design/Design.css";
import { serviceData4 } from "../../Components/CardComponent/ServiceData4";
import { Box, Card, Typography } from "@mui/material";

import img1 from "../../Components/Slider/SliderImages/Gifts/sample1.webp";
import img2 from "../../Components/Slider/SliderImages/Gifts/sample2.webp";
import img3 from "../../Components/Slider/SliderImages/Gifts/sample3.webp";
import img4 from "../../Components/Slider/SliderImages/Gifts/sample4.webp";
import img5 from "../../Components/Slider/SliderImages/Gifts/sample5.webp";
import img6 from "../../Components/Slider/SliderImages/Gifts/sample6.webp";
import img7 from "../../Components/Slider/SliderImages/Gifts/sample7.webp";

import Contact from "../../Components/Contact/Contact";

const Gifts = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    serviceData4().then((res) => setData(res));
  }, []);

  return (
    <div className="services__background">
      <div className="card">
        {data.map((item, index) => (
          <Card
            key={index}
            className="card"
            sx={{
              borderRadius: "20px",
              border: 6,
              borderColor: "#b0db43",
              padding: "20px",
              minHeight: "200px",
              maxWidth: "500px",
            }}
            elevation={15}
          >
            <Box>
              <Typography>
                <div className="card__title">{item.title}</div>
              </Typography>
              <Typography>
                <div className="card__body">{item.description}</div>
              </Typography>
            </Box>
          </Card>
        ))}
      </div>
      <div class="grid">
        <img src={img1} class="grid-item" />
        <img src={img2} class="grid-item" />
        <img src={img3} class="grid-item" />
        <img src={img4} class="grid-item" />
        <img src={img5} class="grid-item" />
        <img src={img7} class="grid-item" />
        <img src={img6} class="grid-item" />
      </div>
    </div>
  );
};

export default Gifts;
