export const serviceData = () => {
  return new Promise((resolve, reject) => {
    resolve(data());
  });
};

const data = () => {
  return [
    {
      id: "985939",
      title: "GRAPHIC DESIGN",
      description:
        "T-shirts and other apparel - Bags - Wall decals - Masks - And more",
    },
    {
      id: "985939",
      title: "PRINTING",
      description:
        "Invitations - Event/raffle tickets - Flyers - Greeting cards - Stickers/Labels - And more",
    },
    {
      id: "985939",
      title: "BUSINESS SOLUTIONS",
      description:
        "Logo design - Business cards - Sales/marketing items - Promo items - Banners - Stationary/business forms - And more",
    },
  ];
};
