import React from "react";
import "../Homepage/Home.css";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import img2 from "../../Components/Navbar/Picture6.webp";
import event_label2 from "../Homepage/Images/All_O_mob_black.png";
import gifts_label2 from "../Homepage/Images/Gift_Ideas_mob_black.png";
import custom_label2 from "../Homepage/Images/Custom_Desings_mob_black.png";

import event_label from "../Homepage/Images/event_label.webp";
import gifts_label from "../Homepage/Images/Gift_Ideas.webp";
import custom_label from "../Homepage/Images/Custom_Designs.webp";
import CustomPic from "../../Components/Slider/SliderImages/Custom/sample27.webp";
import EventPic from "../../Components/Slider/SliderImages/Events/sample10.webp";
import GiftPic from "../../Components/Slider/SliderImages/Gifts/sample8.webp";

export const Home = () => {
  return (
    <div className="home__background">
      <Helmet>
        <title>Another Roe Original</title>
        <meta name="description" content="Bringing Your Ideas To Life" />
      </Helmet>

      <div className="home__container">
        <div className="test444 ">
          <img
            src={img2}
            className="title__custom3 nav__animation--1 text__disappear2 "
          />
        </div>
        <div className="home__sections">
          <div className="home__sections--sub home__animation--1">
            <img
              className="home__image--title text__disappear "
              src={custom_label}
            />
            <img
              className="home__image--title text__disappear2"
              src={custom_label2}
            />
            <CustomLink to="/Design" className="home__image--display">
              <img className="home__image" src={CustomPic} />{" "}
            </CustomLink>
          </div>

          <div className="home__sections--sub home__animation--2">
            <img
              className="home__image--title text__disappear "
              src={event_label}
            />
            <img
              className="home__image--title text__disappear2"
              src={event_label2}
            />
            <CustomLink to="/AllOccasions" className="home__image--display">
              <img className="home__image  " src={EventPic} />
            </CustomLink>
          </div>

          <div className="home__sections--sub home__animation--3">
            <img
              className="home__image--title text__disappear"
              src={gifts_label}
            />
            <img
              className="home__image--title text__disappear2"
              src={gifts_label2}
            />
            <CustomLink to="/Gifts" className="home__image--display">
              <img className="home__image" src={GiftPic} />
            </CustomLink>
          </div>
        </div>

        <div className="home__box">
          <div className="home__intro">
            At Another Roe Original, we are committed to crafting unparalleled
            and distinctive designs, ensuring every creation remains uniquely
            tailored to the individual's vision. Starting from scratch, we
            guarantee that our designs, be it a business card or invite, stand
            apart in their exclusivity. Since 2017, we've meticulously curated
            events, providing a luxurious experience at accessible prices,
            complete with bespoke details down to tablecloth and chair cover
            rentals. Our ethos is to amplify personal affinities in every
            design, proving we not only listen but attentively resonate with
            each client's essence.
          </div>
        </div>
      </div>
    </div>
  );

  function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });

    return (
      <div className={isActive === to ? "active" : ""}>
        <Link to={to} {...props}>
          {children}
        </Link>
      </div>
    );
  }
};
