import React, { useState, useEffect } from "react";
import "../Design/Design.css";
import { serviceData } from "../../Components/CardComponent/ServiceData";
import { Box, Card, Typography } from "@mui/material";

import img1 from "../../Components/Slider/SliderImages/Custom/sample13.webp";
import img2 from "../../Components/Slider/SliderImages/Custom/sample16.webp";
import img3 from "../../Components/Slider/SliderImages/Custom/sample17.webp";
import img4 from "../../Components/Slider/SliderImages/Custom/sample19.webp";
import img5 from "../../Components/Slider/SliderImages/Custom/sample22.webp";
import img6 from "../../Components/Slider/SliderImages/Custom/sample23.webp";
import img7 from "../../Components/Slider/SliderImages/Custom/sample25.webp";
import img8 from "../../Components/Slider/SliderImages/Custom/sample24.webp";
import img9 from "../../Components/Slider/SliderImages/Custom/sample26.webp";
import Contact from "../../Components/Contact/Contact";

const Design = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    serviceData().then((res) => setData(res));
  }, []);

  return (
    <div className="services__background">
      <div className="card">
        {data.map((item, index) => (
          <Card
            key={index}
            className="card"
            sx={{
              borderRadius: "20px",
              border: 6,
              borderColor: "#b0db43",
              padding: "20px",
              minHeight: "200px",
              maxWidth: "500px",
            }}
            elevation={15}
          >
            <Box>
              <Typography>
                <div className="card__title">{item.title}</div>
              </Typography>
              <Typography>
                <div className="card__body">{item.description}</div>
              </Typography>
            </Box>
          </Card>
        ))}
      </div>
      <div class="grid">
        <img src={img1} class="grid-item" />
        <img src={img2} class="grid-item" />
        <img src={img3} class="grid-item" />
        <img src={img4} class="grid-item" />
        <img src={img5} class="grid-item" />
        <img src={img6} class="grid-item" />
        <img src={img7} class="grid-item" />
        <img src={img8} class="grid-item" />
        <img src={img9} class="grid-item" />
      </div>
    </div>
  );
};

export default Design;
