import React, { useState, useEffect } from "react";
import "../Design/Design.css";
import { serviceData3 } from "../../Components/CardComponent/ServiceData3";
import { Box, Card, Typography } from "@mui/material";

import img1 from "../../Components/Slider/SliderImages/Events/sample1.webp";
import img2 from "../../Components/Slider/SliderImages/Events/sample2.webp";
import img3 from "../../Components/Slider/SliderImages/Events/sample3.webp";
import img4 from "../../Components/Slider/SliderImages/Events/sample4.webp";
import img5 from "../../Components/Slider/SliderImages/Events/sample5.webp";
import img6 from "../../Components/Slider/SliderImages/Events/sample6.webp";
import img7 from "../../Components/Slider/SliderImages/Events/sample7.webp";
import img8 from "../../Components/Slider/SliderImages/Events/sample8.webp";
import img9 from "../../Components/Slider/SliderImages/Events/sample9.webp";
import img10 from "../../Components/Slider/SliderImages/Events/sample10.webp";
import Contact from "../../Components/Contact/Contact";

const AllOccasions = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    serviceData3().then((res) => setData(res));
  }, []);

  return (
    <div className="services__background">
      <div className="card">
        {data.map((item, index) => (
          <Card
            key={index}
            className="card"
            sx={{
              borderRadius: "20px",
              border: 6,
              borderColor: "#b0db43",
              padding: "20px",
              minHeight: "200px",
              maxWidth: "500px",
            }}
            elevation={15}
          >
            <Box>
              <Typography>
                <div className="card__title">{item.title}</div>
              </Typography>
              <Typography>
                <div className="card__body">{item.description}</div>
              </Typography>
            </Box>
          </Card>
        ))}
      </div>
      <div class="grid">
        <img src={img1} class="grid-item" />
        <img src={img2} class="grid-item" />
        <img src={img3} class="grid-item" />
        <img src={img4} class="grid-item" />
        <img src={img5} class="grid-item" />
        <img src={img6} class="grid-item" />
        <img src={img7} class="grid-item" />
        <img src={img8} class="grid-item" />
        <img src={img9} class="grid-item" />
        <img src={img10} class="grid-item" />
      </div>
    </div>
  );
};

export default AllOccasions;
