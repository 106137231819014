import emailjs from "emailjs-com";
import React, { useRef } from "react";
import "../Contact/Contact.css";

const Contact = () => {
  const form = useRef();
  const [clicks, setClicks] = React.useState(0);
  const [sendEmailLoading, setSendEmailLoading] = React.useState(false);
  const [sendEmailStatus, setSendEmailStatus] = React.useState("");

  const sendEmail = async (e) => {
    e.preventDefault();
    setClicks(clicks + 1);
    if (clicks > 1) {
      return;
    }

    setSendEmailLoading(true);

    try {
      await emailjs.sendForm(
        "service_fx0zsad",
        "template_wygho7d",
        form.current,
        "wnRQW9j14eby8DJQj"
      );
      setSendEmailLoading(false);
      setSendEmailStatus("success");
    } catch (error) {
      setSendEmailLoading(false);
      setSendEmailStatus("fail");
    }
  };

  return (
    <div className="info__wrapper">
      <div className="contact__wrapper">
        <div className="contact__info--title">
          Feel free to contact me for a consult
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <div className="input__wrapper">
            <label className="name">Name</label>
            <br />
            <input id="name" type="text" name="name" required />
          </div>

          <div className="input__wrapper">
            <label className="name">Phone Number</label>
            <br />
            <input id="phoneNumber" type="text" name="phoneNumber" required />
          </div>

          <div className="input__wrapper">
            <label className="name">Email</label>
            <br />
            <input id="email" type="email" name="email" required />
          </div>

          <div className="input__wrapper">
            <label className="name">Message</label>
            <br />
            <textarea id="message" name="message" required />
          </div>

          {sendEmailLoading && <div id="form__message">Sending email...</div>}
          {sendEmailStatus === "success" && (
            <div id="form__message">Email sent!</div>
          )}
          {sendEmailStatus === "fail" && (
            <div id="form__message">Email failed to send.</div>
          )}
          <div className="contact__button--wrapper">
            <button
              id="contact__contact--button"
              className="contact__contact--button"
              type="submit"
              value="Send"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
