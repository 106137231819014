import { React } from "react";
import "../Navbar/Navbar.css";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import img from "../Navbar/Picture5.webp";
import img2 from "../Navbar/Picture4.webp";

const Navbar2 = () => {
  return (
    <div className="nav__container">
      <CustomLink to="/" className="nav__link link__design">
        <img src={img} className="title__custom nav__animation--2" />
      </CustomLink>
      <nav>
        <div>
          <img
            src={img2}
            className="title__custom2 nav__animation--1 text__disappear"
          />
        </div>
        <div className="nav__container--nav">
          <ul>
            <CustomLink
              to="/Design"
              className="nav__link link__design nav__animation--1"
            >
              Custom Design
            </CustomLink>
            <CustomLink
              to="/AllOccasions"
              className="nav__link link__events nav__animation--1"
            >
              All Occasions
            </CustomLink>
            <CustomLink
              to="/Gifts"
              className="nav__link link__gifts nav__animation--1"
            >
              Personalized Gifts
            </CustomLink>
          </ul>
        </div>
      </nav>
    </div>
  );
};

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive === to ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}

export default Navbar2;
