import "./App.css";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import Design from "./Pages/Design/Design";
import AllOccasions from "./Pages/Events/AllOccasions";
import Gifts from "./Pages/Gifts/Gifts";
import { Home } from "./Pages/Homepage/Home.jsx";
import { Route, Routes } from "react-router-dom";
import { React } from "react";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const HelmetContext = {};
  return (
    <div className="wholepage">
      <HelmetProvider context={HelmetContext}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Design" element={<Design />} />
          <Route path="/AllOccasions" element={<AllOccasions />} />
          <Route path="/Gifts" element={<Gifts />} />
        </Routes>
        <Contact />
        <Footer />
      </HelmetProvider>
    </div>
  );
}

export default App;
