export const serviceData4 = () => {
  return new Promise((resolve, reject) => {
    resolve(data());
  });
};

const data = () => {
  return [
    {
      id: "985939",
      title: "Gift Baskets",
      description:
        "Gift baskets for all occasions - Baby Showers - Weddings - Birthdays - Get Well - Easter - Valentine’s Day - Teacher Appreciation - Just because..",
    },
    {
      id: "985939",
      title: "Hand-Crafted Gifts",
      description:
        "Door wreaths - Diaper cakes - Drinkware - Graduation caps - Cake toppers - Paper flower bouquets - And more!",
    },
  ];
};
