import React from "react";
import "../Footer/Footer.css";
import PoweredBy from "../PoweredBy/PoweredBy";

const Footer = () => {
  return (
    <div className="footer__container">
      <div>
        <br />
        &#169; copyright 2023 Another Roe Original
      </div>

      <div>
        <br />
        LeRoina Campbell
        <br />
        201-620-4504
        <br />
        anotherroeoriginal@gmail.com
      </div>

      <div>
        <br />
        <PoweredBy />
      </div>
    </div>
  );
};

export default Footer;
