export const serviceData3 = () => {
  return new Promise((resolve, reject) => {
    resolve(data());
  });
};

const data = () => {
  return [
    {
      id: "985939",
      title: "EVENTS",
      description:
        "Set up and break down services - Mobile Bartending - Centerpieces - Backdrops - Candy Bars - Party favors - And more",
    },
    {
      id: "985939",
      title: "BALLOON ART",
      description:
        "Organic balloon garlands - Balloon columns - Balloon arches - Centerpieces - And more",
    },

    {
      id: "985939",
      title: "RENTALS",
      description:
        "Chairs and tables - Table linens - Chair covers - Chair sashes - Glassware - Balloon pumps - Small acrylic signs - And more",
    },
  ];
};
